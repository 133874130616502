<template>
    <div class="px-page mt-5">
        <v-tabs fixed-tabs v-model="tab">
            <v-tab>
                Allgemein
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <NextcloudGeneral :nextcloud="nextcloud" :service="service" />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import xeonserv from '../../../apis/xeonserv'
import NextcloudGeneral from './NextcloudGeneral'

export default {
    components: { NextcloudGeneral },
    data() {
        return {
            tab: 0,
            nextcloud: {
                id: this.$route.params.id,
                disk: 0,
            },
            service: {
                id: 0,
                expired: false
            }
        }
    },
    created() {
        xeonserv().get('/nextcloud-servers/' + this.nextcloud.id).then(res => this.nextcloud = res.data.data)
        xeonserv().get('/nextcloud-servers/' + this.nextcloud.id + '/service').then(res => {
            console.log(res)
            this.service = res.data.data
        })
        this.$on('setnextcloud', nextcloud => this.nextcloud = nextcloud)
    }
}
</script>