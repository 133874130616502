<template>
    <v-row class="mt-5 mb-5">
        <v-col md="8">
            <v-row dense>
                <v-col md="6">
                    <v-text-field label="Name" outlined readonly dense :value="'nc' + nextcloud.id + '.service.xeonserv.cloud'" />
                </v-col>
                <v-col md="6">
                    <v-text-field label="Speicher" outlined readonly dense :value="nextcloud.disk + ' GB'" />
                </v-col>
                <v-col md="6">
                    <v-text-field label="Nextcloud Benutzer" outlined readonly dense :value="'admin'" />
                </v-col>
                <v-col md="6">
                    <v-text-field label="Nextcloud Passwort" outlined readonly dense :value="nextcloud.password" @click="hidePassword = !hidePassword" :type="hidePassword ? 'password' : 'text'" />
                </v-col>
                <v-col md="12">
                    <v-text-field label="Restlaufzeit" outlined readonly dense :value="remainingTime" />
                </v-col>
            </v-row>
        </v-col>
        <v-col md="4">
            <v-btn block class="mb-2" color="primary">Verlängern</v-btn>
            <!--<NextcloudUpgrade :nextcloud="nextcloud" :service="service" />-->
        </v-col>
    </v-row>
</template>

<script>
import interval from '../../../util/interval'
//import NextcloudUpgrade from './NextcloudUpgrade'

export default {
    //components: { NextcloudUpgrade },
    data() {
        return {
            now: new Date(),
            hidePassword: true
        }
    },
    timers: {
        updateNow: { time: 1000, autostart: true, repeat: true, callback() { this.now = new Date() }}
    },
    props: {
        nextcloud: {
            type: Object
        },
        service: {
            type: Object
        }
    },

    computed: {
        displayStatus() {
            if(this.service.expired)
                return 'Abgelaufen'
            return 'Online'
        },
        remainingTime() {
            this.now;
            return interval.format(interval.until(this.service.expire_at))
        }
    }
}
</script>